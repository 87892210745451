import { Trans, useTranslation } from 'react-i18next'
import { useCallback, useEffect, useState } from 'react'
import { Button, Divider, Link, LoadingDots, Paragraph, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useSearchParams } from 'next/navigation'

import { Link as RouterLink, usePathname, useRouter } from '../../../vendor/next'
import { SectionHeading, SectionBody } from '../components'
import { useAuthDialogContext } from '../../../context/auth-dialog-context'
import { useAuthContext } from '../../../context/auth-context'
import { login as schibstedLogin } from '../../../vendor/schibsted-identity-client'
import { BRAND_TOGGLES } from '../../../brands/toggles'
import { decodeAfterLoginParameters, encodeAfterLoginParameters } from '../../../helpers/auth-callback'
import { AFTER_LOGIN_ACTIONS } from '../../../helpers/auth-callback.types'
import { getPath } from '../../../routing/get-path'
import type { AuthenticationSectionProps } from '../authentication-section.types'
import { getP1AccessToken } from '../../../helpers/p1-auth'
import {
  DotcomPlatformSelectorDialog,
  shouldDisplayDotcomPlatformSelectorDialog,
} from '../../../feature-modules/dotcom-platform-selector-dialog/dotcom-platform-selector-dialog'
import type { SchibstedSSOMarket } from '../../../brands/index.types'
import { LinkButtonV2 } from '../../../ui-shared/_core/link-button-v2'

import { LoginForm } from './login-form'

const ContentWrapper = styled(Stack)({
  minHeight: 300,
})

const CenteredButton = styled(Button)({
  alignSelf: 'center',
})
const CenteredLinkButton = styled(LinkButtonV2)({
  alignSelf: 'center',
})
export function Login({ variant }: AuthenticationSectionProps) {
  const { t } = useTranslation('authentication_flow')
  const { isAuthenticated } = useAuthContext()
  const { isAuthDialogOpen, setIsAuthDialogOpen } = useAuthDialogContext()
  const [isLoadingSchibstedLogin, setIsLoadingSchibstedLogin] = useState(false)
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const state = searchParams.get('state')
  const searchParamsString = searchParams.toString() ? `?${searchParams.toString()}` : ''

  const isOnLoginPage = pathname.startsWith('/login')
  const router = useRouter()
  const { availableLoginMethods } = BRAND_TOGGLES
  const isNativeApp = window?.isNativeApp

  useEffect(() => {
    if (!isAuthenticated) {
      return
    }

    if (isAuthDialogOpen) {
      setIsAuthDialogOpen(false)
    }
    /**
     * Redirect to /auth-callback if there is any after-login action, or to /messages if
     * user is currently on the /login page.
     * Otherwise stay on the current path.
     */

    const accessToken = getP1AccessToken()

    if (accessToken && isNativeApp && window?.postAccessToken) {
      window.postAccessToken({ accessToken })
    }
    const shouldRedirect = (state || isOnLoginPage) && !isNativeApp
    if (shouldRedirect) {
      const newPath = state ? getPath('authCallback') : getPath('messages')
      const newHref = newPath + searchParamsString
      router.replace(newHref)
    }
  }, [
    isAuthenticated,
    router,
    state,
    isAuthDialogOpen,
    setIsAuthDialogOpen,
    isNativeApp,
    isOnLoginPage,
    searchParamsString,
  ])

  const schibstedAuthMethods = availableLoginMethods.filter((method): method is SchibstedSSOMarket =>
    ['schibsted_fi', 'schibsted_se'].includes(method),
  )
  const singleSchibstedAuthMethod =
    schibstedAuthMethods.length === 1 && availableLoginMethods.length === 1 ? schibstedAuthMethods[0] : null

  const handleSchibstedLogin = useCallback(
    ({ market }: { market: SchibstedSSOMarket }) => {
      setIsLoadingSchibstedLogin(true)
      const decodedState = typeof state === 'string' ? decodeAfterLoginParameters(state) : null

      // Go back to the current route after login if no other after-login action is set
      const action = decodedState?.action ?? {
        type: AFTER_LOGIN_ACTIONS.GO_TO_ROUTE,
        route: pathname,
        search: searchParamsString,
      }
      const encodedStateWithSchibstedMarket = encodeAfterLoginParameters({
        action,
        schibstedSSOMarket: market,
      })

      if (!isAuthenticated) {
        schibstedLogin({
          state: encodedStateWithSchibstedMarket,
          market,
        })
      }
    },
    [isAuthenticated, state, pathname, searchParamsString],
  )

  /**
   * If there is only one Schibsted auth method we want to redirect user to login
   * without having to press a button.
   */
  useEffect(() => {
    if (singleSchibstedAuthMethod) {
      handleSchibstedLogin({ market: singleSchibstedAuthMethod })
    }
  }, [handleSchibstedLogin, singleSchibstedAuthMethod])

  if (isAuthenticated || isLoadingSchibstedLogin) {
    return (
      <ContentWrapper alignItems="center" justifyContent="center">
        <LoadingDots />
      </ContentWrapper>
    )
  }

  if (shouldDisplayDotcomPlatformSelectorDialog()) {
    return <DotcomPlatformSelectorDialog />
  }
  return (
    <>
      <SectionHeading variant={variant}>{t('login.heading')}</SectionHeading>
      <SectionBody variant={variant}>
        <Stack divider={<Divider />} gap={'6x'}>
          <Stack gap="4x">
            {availableLoginMethods.map((method) => {
              switch (method) {
                case 'schibsted_se':
                case 'schibsted_fi':
                  return (
                    <CenteredButton
                      variant="tertiary"
                      onClick={() => handleSchibstedLogin({ market: method })}
                      key={method}
                    >
                      {t('login_with', { context: method })}
                    </CenteredButton>
                  )
                case 'one_time_password':
                  return (
                    <CenteredLinkButton
                      variant="tertiary"
                      href={`${getPath('otpLogin')}${searchParamsString}`}
                      key={method}
                    >
                      {t('login_with_email')}
                    </CenteredLinkButton>
                  )
                case 'qasa':
                  return <LoginForm key={method} />
                default:
                  return null
              }
            })}
          </Stack>
          <Paragraph textAlign="center">
            <Trans
              t={t}
              i18nKey={'signup_link_text'}
              components={[<Link key="0" as={RouterLink} href={'/signup' + searchParamsString} />]}
            />
          </Paragraph>
        </Stack>
      </SectionBody>
    </>
  )
}
